@media only screen and (max-width: 3200px) {
    .video {
        position: fixed;
        top: 50%;
        left: 50%;
        width: 60%;
        transform: translate(-50%, -50%);
        border-radius: 25px;
        color: white;
    }
    .loading-title {
        position: absolute;
        top: 30%;
        left: 50%;
        transform: translate(-50%, -50%);
        color: white;
    }
    .loading {
        position: fixed;
        top: 20%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 100%;
    }
}

@media only screen and (max-width: 600px) {
    .video {
        position: fixed;
        top: 45%;
        left: 50%;
        width: 85%;
        transform: translate(-50%, -50%);
        border-radius: 25px;
        color: white;
    }
    .loading-title {
        position: absolute;
        top: 30%;
        left: 50%;
        transform: translate(-50%, -50%);
        color: white;
    }
    .loading {
        position: fixed;
        top: 30%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 100%;
    }
}

a {
    color: white
}

button {
    margin: 5px;
}

h1 {
    margin-block-end: 0em;
    font-size: 38px;
    -webkit-text-stroke: 1px gray;
}

p {
    font-size: 20px;
}

.buttons a {
    color: black;
    text-decoration: none;
    font-size: 16px;
}

.password {
    position: fixed;
    top: 33%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 25px;
    color: white;
}

#error-message {
    color: red;
}